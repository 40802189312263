import React, { useEffect, useRef, useState } from "react";
import shoutimg from "../../../assests/images/others/Group 1771.png";

import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Announcement() {
  const [announcementData, setNoticeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);
  const scrollInterval = useRef(null);

  useEffect(() => {
    const getAnnounc = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_ANNOUNCEMENT);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/announcement"
        // );
        setNoticeData(response.data.announcements);
        console.log(response);
      } catch (error) {
        console.error("Error fetching announcement:", error);
      } finally {
        setLoading(false);
      }
    };

    getAnnounc();
  }, []);


  useEffect(() => {
    if (announcementData.length === 0) return;

    const startScrolling = () => {
      scrollInterval.current = setInterval(() => {
        if (scrollRef.current) {
          if (
            scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
            scrollRef.current.scrollHeight
          ) {
            // Reset to top when reaching bottom
            scrollRef.current.scrollTo({ top: 0, behavior: "instant" });
          } else {
            scrollRef.current.scrollBy({

              top: 0.8, // Adjust speed
              behavior: "smooth",
            });
          }
        }
      }, 80); // Adjust interval for smoother scrolling
    };

    startScrolling();

    return () => clearInterval(scrollInterval.current);
  }, [announcementData]);

  const handleMouseEnter = () => clearInterval(scrollInterval.current);
  const handleMouseLeave = () => {
    scrollInterval.current = setInterval(() => {
      if (scrollRef.current) {
        if (
          scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
          scrollRef.current.scrollHeight
        ) {
          scrollRef.current.scrollTo({ top: 0, behavior: "instant" });
        } else {
          scrollRef.current.scrollBy({
            top: 0.8,
            behavior: "smooth",
          });
        }
      }
    }, 80);
  };


  if (loading) return <p>Loading...</p>;

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      <div className="headerdiv3 ">
        <img src={shoutimg} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2 >Announcements</h2>
          <Link to="/notices" className="linerm">
            <p className="mb-0">
              View All{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event p-3"
         ref={scrollRef}
         style={{
          //  maxHeight: "200px", // Adjust height as needed
           overflowY: "hidden",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
      >
        {announcementData.length > 0 &&
          announcementData.map((notice, index) => (
            <p
              className="notice py-2"
              style={{ cursor: "pointer" }}
              key={index}
            >
              <a
                href={notice.link}
                onClick={() =>
                  handleDownload(
                    process.env.REACT_APP_API_URL + notice.announcementMedia
                  )
                }
                className="linerm"
                target="_blank"
                rel="noopener noreferrer"
              >
                {notice.title}
                {index < 2 && (
                <span className="mx-1">
                  <Badge bg="danger">New</Badge>
                </span>
              )}
              </a>
              {notice.btn && <span className="notice-btn">{notice.btn}</span>}
            </p>
          ))}
      </div>
    </div>
  );
}
