import axios from "axios";
import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GrevienceForm() {
  console.log(process.env.REACT_APP_SEND_COMPLAINT, "test complaint");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    hostel: "",
    mobile: "",
    standard: "",
  });
  //   const [showSuccess, setShowSuccess] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendComplaint = await axios.post(
        process.env.REACT_APP_SEND_COMPLAINT,
        formData
      );
      //   handle success
      console.log("Complaint send successfully", sendComplaint);
      //   setShowSuccess(true);
      toast.success("Complaint send successfully");

      setTimeout(() => {
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
          hostel: "",
          mobile: "",
          standard: "",
        });
      }, 2000);
    } catch (error) {
      console.log("Error sending Complaint :", error);
      toast.error("Error sending Complaint");
    }
  };

  return (
    <div className="mt-5">
      <p className="titles text-center">Redressal of Grievances (Students)</p>
      <div className=" grev-sub mt-2 d-flex justify-content-center">
        <div className="col-lg-6 col-sm-12">
          <div className="grev-form">
            {/* {showSuccess && (
              <div className="alert alert-success" role="alert">
                Complaint sent successfully!
              </div>
            )} */}
            <button className="grev-btn">Register Complaint</button>
            <form className="w-100" onSubmit={handleSubmit}>
              <div className="py-4 w-100">
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="name"
                    name="name"
                    placeholder=" Name"
                    value={formData.name}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="standard"
                    name="standard"
                    placeholder="Class"
                    value={formData.standard}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="hostel"
                    name="hostel"
                    placeholder="Hostel"
                    value={formData.hostel}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="email"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="email"
                    name="email"
                    placeholder="Email-id"
                    value={formData.email}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="number"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile no"
                    value={formData.mobile}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="text"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    required
                  />
                </div>
                <div className="form-group mb-2">
                  <input
                    type="textarea"
                    className="form-control custom-input"
                    onChange={handleInputChange}
                    id="message"
                    name="message"
                    placeholder="Grievance"
                    value={formData.message}
                    required
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button className="send">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
