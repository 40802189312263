import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TeachingStaffData, NonTeachingStaffData } from "../../StaffData";
import teaching from "../../assests/images/others/teaching.svg";
import nonteaching from "../../assests/images/others/nonteaching.svg";
export default function Staff() {
  const navigate = useNavigate();
  const [selectedStaff, setSelectedStaff] = useState(null);

  const handleClick = (id, staffType) => {
    setSelectedStaff(id);
    console.log(id);
    navigate(`/aboutstaff/${staffType}/${id}`);
  };

  return (
    <div className="text-center mt-5 ">
      <div id="teaching">
        <img src={teaching} alt="" className="img-fluid " />
        <div className="d-flex p-2 flex-wrap justify-content-center">
          {TeachingStaffData.map((team, i) => (
            <div
              key={i}
              className="teamcarddiv mt-5"
              style={{ cursor: "pointer", marginBottom: "5rem !important",marginTop: "5rem !important" }}
            >
              <div
                className="teamcard mt-4 mb-3"
                onClick={() => handleClick(team.id, "teaching")}
              >
                <div className="formdiv prosimg   position-relative">
                  <img
                    src={team.img}
                    className="card-img-top img-fluid"
                    alt="Card Image"
                  />
                  <div className="overlay">
                    <p className="overlay-text">
                      <small className=" p-2">View Profile</small>
                    </p>
                  </div>

                  <div className="card-body px-1 py-2  ">
                    <h5 className="teamname">{team.name}</h5>
                    <p className="destiny ">{team.post}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="nonteaching" className="mt-5">
        <img src={nonteaching} alt="" className="img-fluid" />
        <div className="d-flex p-2 flex-wrap justify-content-center">
          {NonTeachingStaffData.map((team, i) => (
            <div key={i} className="teamcarddiv mt-5">
              <div className="teamcard">
                <img
                  src={team.img}
                  className="card-img-top img-fluid"
                  alt="Card Image"
                />
              </div>
              <div className="card-body  py-2 ">
                <h5 className="teamname">{team.name}</h5>
                <p className="destiny ">{team.post}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
