import React from "react";
import rightarrow from "../../assests/images/others/arrow_right_alt_FILL0_wght400_GRAD0_opsz24.svg";
import { Link, useNavigate } from "react-router-dom";
import { committeeData } from "../../CommiteeData";

export default function CollegeC() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="aboutbg">
        <div className="about-banner">
          <h2 className="title">SOCIETIES & CELLS</h2>
        </div>
      </div>
      <div className="collegec ">
        <div className="d-flex justify-content-center pt-5 container">
          <div className="title-btn">
            <div className="title-border">SOCIETIES & CELLS</div>
          </div>
        </div>
      
        <div className="container d-flex justify-content-center">
      <div className="cmtblockdiv">
        {Object.keys(committeeData)
          .filter((key) => !["NAAC", "JOBS"].includes(key))
          .map((key) => (
            <div
              className="cmtblock"
              key={key}
              onClick={() => {
                navigate(`/committee/${key}`);
                window.scrollTo(0, 0); // Scroll to the top on click
              }}
              style={{ cursor: "pointer" }}
            >
              <p className="mb-0">{committeeData[key].title}</p>

              <div className="arowdiv">
                <img
                  src={rightarrow}
                  alt=""
                  title="Click here to view"
                  height='12px'
                  width='15px'
                  className="imgfluid"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
      </div>
    </div>
  );
}
