import React, { useEffect, useRef, useState } from "react";
import aboutimg from "../../assests/images/others/about1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import video from "../../assests/images/ALC 1st NATIONAL SEMINAR 2023.mp4";

export default function About() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (videoRef.current) {
        const windowHeight = window.innerHeight;
        const videoPosition = videoRef.current.getBoundingClientRect().top;
        const isVisible = videoPosition < windowHeight && videoPosition > 0;
        setIsPlaying(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  // const toggleVideo = () => {
  //   setShowVideo(true);
  //   setIsPlaying(!isPlaying);
  // };
  const toggleVideo = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      setShowVideo(true);
    }
  };

  return (
    <div
      className="container mt-3 d-flex align-items-center flex-wrap"
      id="about"
    >
      <div className="aboutdiv col-lg-6 col-12">
        <h2>About Army Law College Pune</h2>
        <p className="mt-4 about-text">
          Army Law College, Pune was established in July 2018 with a noble
          objective of imparting quality legal education to the children of our
          brave, patriotic & selfless Army personnel. The College is governed
          and managed by Army Welfare Education Society (AWES), which is non-
          profit organization, registered under the Societies Registration Act
          XXI of 1860, since 29 April 1983. The Army Law College is the most
          recent professional college to be established in Pune under AWES. The
          location of the college is serene and ideal for educational institute.
          ALC is well connected by national highway and local train. Army Law
          College, Pune is affiliated to “Savitribai Phule Pune University”
          (SPPU) which is among the top state universities with consistent NAAC
          A+ grade. Army Law College, Pune is approved by the Bar Council of
          India and Government of Maharashtra. It offers five- years integrated
          B.B.A, LL. B & B.A. LL.B. programs each with a student intake of 60
          seats (exclusively for children of Army personnel).
        </p>
        <p className="about-text">
          The institution originated with the donation of initial campus and
          buildings by Dr. Prem Daryanani, Managing Trustee of Radha Kalyandas
          Daryanani Charitable Trust, has now developed into a 14-acer
          educational hub with the state-of-the-art academic infrastructure.
          Ample facilities for sports, hobbies and extracurricular activities
          exists on the campus for holistic development of the students. The
          College also has NSS & NCC sub-units.
        </p>
        <div className="d-flex mt-4 justify-content-between">
          <div className="datadiv">
            <p className="count">280+</p>
            <p className="dataname">Students</p>
          </div>
          <div className="datadiv">
            <p className="count">15+</p>
            <p className="dataname">Professors</p>
          </div>
          <div className="datadiv">
            <p className="count">35%</p>
            <p className="dataname">Placed</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <div className="img-container mx-5">
          <img
            src={aboutimg}
            alt=""
            className={showVideo ? "d-none" : "img-fluid"}
          />
          {showVideo && (
            <video
              ref={videoRef}
              className="img-fluid"
              onClick={toggleVideo}
              controls
            >
              <source src={video} type="video/mp4" />
            </video>
          )}
          {!showVideo && (
            <div className="playdiv" onClick={toggleVideo}>
              <FontAwesomeIcon  style={{  marginTop: "15px",marginLeft:"4px" }}  icon={faCaretRight} className="playicon" />
              <p>{isPlaying ? "Pause" : ""}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
