import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ArchiveData } from "../../AddmissionData";
import axios from "axios";

export default function Notices() {
  const [coursesData, setcetnotice] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCetNotice = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_CET_NOTICS);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/cetnotices"
        // );
        setcetnotice(response.data.cetNotices);
        console.log(response);
      } catch (error) {
        console.error("Error fetching CET notices:", error);
        setError("Failed to fetch CET notices");
      } finally {
        setLoading(false);
      }
    };
    getCetNotice();
  }, []);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const combinedArchiveData = [
    ...ArchiveData.map((data) => ({ text: data.text })),
    ...coursesData.map((data) => ({ text: data.CetNoticeTitle })),
  ];

  return (
    <div className="container" id="notices">
      <div className="mt-5">
        <div className="mt-5 d-flex flex-wrap justify-content-center">
          {coursesData.map((data, i) => (
            <div className="formdiv prosimg m-2 position-relative" key={i}>
              <img
                src={process.env.REACT_APP_API_URL + data.CetNoticeMedia}
                alt=""
                className="img-fluid p-2"
              />
              <div className="overlay">
                <a
                  href={process.env.REACT_APP_API_URL + data.CetNoticeMedia}
                  download={process.env.REACT_APP_API_URL + data.CetNoticeMedia}
                  className="text-decoration-none"
                >
                  <p className="overlay-text">
                    <small className=" p-2">{data.CetNoticeTitle}</small>
                  </p>
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex mt-5">
          <div className="col-lg-2 col-sm-3">
            <div className="archive">
              ARCHIVE <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div className="d-flex flex-column col-lg-9 col-sm-9 mx-2">
            {combinedArchiveData.map((data, i) => (
              <p className="progtext arc-sidetext p-3 mt-0" key={i}>
                {data.text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
