import React, { useState, useRef, useEffect } from 'react';
import aboutimg from '../../../assests/images/others/about1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import video from '../../../assests/images/ALC 1st NATIONAL SEMINAR 2023.mp4'

export default function Campus() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showVideo, setShowVideo] = useState(false); 
    const videoRef = useRef(document.createElement('video')); 

    useEffect(() => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.play();
                
            } else {
                videoRef.current.pause();
                
            }
        }
    }, [isPlaying]);

    const toggleVideo = () => {
        setIsPlaying(!isPlaying);
        setShowVideo(!showVideo); 
    };

    return (
        <div className='container '>  
            <div>
                <div>
                    <div className=" d-flex justify-content-center position-relative">
                    <img src={aboutimg} alt='' className={showVideo ? 'd-none' : 'img-fluid '} />
                    {showVideo &&
                            <video
                                ref={videoRef}
                                className='img-fluid'
                                style={{ display: isPlaying ? 'block' : 'none' }}
                                onClick={toggleVideo}
                                 controls
                                 autoPlay
                            >
                                <source src={video} type='video/mp4' />
                            </video>
                        }
                        <div className='playdiv' onClick={toggleVideo}>
                            <FontAwesomeIcon icon={faCaretRight} className='playicon' />
                            <p>{isPlaying ? 'Pause' : 'Play'}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
