import React from "react";
import img1 from "../../../assests/images/infrastructure/6A- New Girls Hostel - Inauguration 13 Aug 2021.png";
import img2 from "../../../assests/images/infrastructure/girls-hostel_alc-pune (2).png";
import img3 from "../../../assests/images/infrastructure/19- Play Grounds/WhatsApp Image 2024-06-13 at 12.01.44_b28f3f2e.jpg";
import img4 from "../../../assests/images/infrastructure/Girls Hostel Mess.png";
import img5 from "../../../assests/images/infrastructure/Girls Hostel Rooms.png";

export default function GHostel() {
  return (
    <div className="container infra d-flex flex-wrap align-items-center">
      <div className="p-3">
        <p className="progsubhead">Lakshmibai Girls Hostel :</p>
        <p className="infrasub">
          Entry Lounge, 3 Bed Sharing 55 Rooms (for 165 girls), Common Toilets &
          Washrooms, Central Kitchen & Utility, 108 seater Dining Hall, Central
          Common/TV Room, Gymnasium, open terrace and Service Lift.
        </p>
        <p className="infrasub2">
          The consolidated capacity of Girls hostels is 205.
        </p>
      </div>
      <div className="col-lg-6 col-sm-12 blockimg p-3">
        <img src={img1} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-6 col-sm-12 blockimg p-3">
        <img src={img2} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-4 col-sm-12 blockimg p-3">
        <img src={img3} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-4 col-sm-12 blockimg p-3">
        <img src={img4} alt="" className="img-fluid" />
      </div>
      <div className="col-lg-4 col-sm-12 blockimg p-3">
        <img src={img5} alt="" className="img-fluid" />
      </div>
    </div>
  );
}
