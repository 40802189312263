import React, { useEffect, useState } from "react";

import strip from "../../../assests/images/Award/Group 143.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";

export default function Award() {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const [images, setawarddata] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAward = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_AWARDS);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/award"
        // );
        setawarddata(response.data.awards);
        console.log(response);
      } catch (error) {
        console.error("Error fetching award data:", error);
      } finally {
        setLoading(false);
      }
    };
    getAward();
  }, []);
  if (loading) return <p>Loading...</p>;

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <div className="awardsection container my-5">
      <h2 className="titles">Student Awards & Recognitions</h2>
      <div className="award mt-5">
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={50}
          slidesPerView={3}
          pagination={{ clickable: true }}
          onSwiper={setSwiper}
          autoplay={{ delay: 3000 }}
          loop={true}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            425: { slidesPerView: 1, spaceBetween: 20 },
            540: { slidesPerView: 2, spaceBetween: 20 },
            999: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {images.map((img, index) => (
            <SwiperSlide key={index}>
              <div className="mt-5 d-flex justify-content-center flex-column align-items-center award-card">
                <div className="position-relative">
                  <img src={strip} alt="" className="img-fluid strip" />
                  <img
                    alt=""
                    src={process.env.REACT_APP_API_URL + img.studentImg}
                    className="img-fluid "
                  />
                </div>
                <div className="holder">
                  <p className="name">{img.studentName}</p>
                  <p className="position">{img.awardedFor}</p>
                  <p className="awardyear">{img.awardDescription}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
