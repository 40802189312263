import "./assests/css/style.css";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import "bootstrap/dist/css/bootstrap.min.css";
import StaffPage from "./pages/StaffPage";
import ProgramsPage from "./pages/ProgramsPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Infrastructure from "./pages/Infrastructure";
import AboutStaffPage from "./pages/AboutStaffPage";
import AlumniPage from "./pages/AlumniPage";
import StudentLife from "./pages/StudentLife";
import CodeCPage from "./pages/CodeCPage";
import Library from "./pages/Library";
import Admissions from "./pages/Admissions";
import Events from "./pages/Events";
import Cultural from "./pages/Cultural";
import NoticesPage from "./pages/NoticesPage";
import ContactPage from "./pages/ContactPage";
import NotFoundPage from "./pages/NotFoundpage";
import CollegeC from "./components/socities/CollegeC";
import ProgramsPage2 from "./pages/ProgramsPage2";
import EventDetail from "./components/Events/EventDetail";
import Societies from "./pages/Societies";
import ProgramsPage3 from "./pages/ProogramPage3";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/staff" element={<StaffPage />} />
          <Route path="/programs" element={<ProgramsPage />} />
          <Route path="/infra" element={<Infrastructure />} />
          <Route
            path="/aboutstaff/:staffType/:id"
            element={<AboutStaffPage />}
          />
          <Route path="/alumni" element={<AlumniPage />} />
          <Route path="/studentlife" element={<StudentLife />} />
          <Route path="/codeconduct" element={<CodeCPage />} />
          <Route path="/library" element={<Library />} />
          <Route path="/addmission/:tab" element={<Admissions />} />
          <Route path="/event/:name/:id" element={<EventDetail />} />
          <Route path="/events" element={<Events />} />
          <Route path="/committee/:key" element={<Cultural />} />
          <Route path="/notices" element={<NoticesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/collegec" element={<CollegeC />} />
          <Route path="/bbaprogram" element={<ProgramsPage2 />} />
          <Route path="/courseprogram3" element={<ProgramsPage3 />} />

          <Route path="/societies" element={<Societies />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
