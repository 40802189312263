import React, { useState, useEffect } from "react";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function EventPhotos() {
  const [selectedYear, setSelectedYear] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  console.log(selectedYear);

  const navigate = useNavigate();

  // useEffect(() => {
  //   fetchEvents(selectedYear);
  //   fetchSessionList();
  // }, [selectedYear]);

  useEffect(() => {
    fetchSessionList();
  }, []);

  useEffect(() => {
    if (selectedYear) {
      fetchEvents(selectedYear);
    }
  }, [selectedYear]);

  const fetchEvents = async (year) => {
    try {
      const response = await axios.post(process.env.REACT_APP_EVENTS, {
        eventSession: year,
      });
      // const response = await axios.post(
      //   "http://admin.alcpune.com:8000/api/events",
      //   {
      //     eventSession: year,
      //   }
      // );
      console.log("eventimg", response);
      setEventsData(response.data.events || []);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const fetchSessionList = async () => {
    try {
      const sessionResponse = await axios.get(process.env.REACT_APP_ALL_EVENTS);
      // const sessionResponse = await axios.get(
      //   "http://admin.alcpune.com:8000/api/allevent"
      // );
      setSessionList(sessionResponse.data.SessionList || []);
      console.log(sessionResponse.data.SessionList);
      if (sessionResponse.data.SessionList.length > 0) {
        setSelectedYear(sessionResponse.data.SessionList[0]);
      }
    } catch (error) {
      console.error("Error fetching session list:", error);
    }
  };

  const handleClick = (name, id) => {
    navigate(`/event/${name}/${id}`);
  };

  const toggleMobileDrop = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectYear = (year) => {
    setSelectedYear(year);
    setIsOpen(true);
    setIsMobileOpen(false);
  };

  return (
    <div className="d-flex flex-wrap mt-5 container">
      <div className="col-lg-3 col-sm-12 col-md-3 px-3 ">
        <FontAwesomeIcon
          icon={faBars}
          className="eventbar"
          onClick={toggleMobileDrop}
        />
        {isMobileOpen && (
          <ul className="position-absolute Eventmobiledrop">
            {sessionList.map((menuItem, index) => (
              <li
                key={index}
                className="Edropdown"
                onClick={() => setSelectedYear(menuItem)}
              >
                {menuItem}
              </li>
            ))}
          </ul>
        )}
        <div className="EdropdownMenu">
          <li className="Edropdown" onClick={toggleDropdown}>
            {selectedYear}{" "}
            <FontAwesomeIcon
              icon={isOpen ? faAngleUp : faAngleDown}
              className=""
            />
          </li>

          {isOpen && (
            <ul>
              {" "}
              {sessionList.map((year, index) => (
                <li key={index} onClick={() => selectYear(year)}>
                  {year}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="col-lg-9 col-sm-12 col-sm-9 d-flex flex-wrap px-2">
        {eventsData.map((data, i) => (
          <div className="col-lg-4 col-sm-10 col-md-6 p-2" key={i}>
            <div
              className="eventimg "
              onClick={() => handleClick(data.EventName, data._id)}
            >
              <div className="e-img">
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    //   data.EventImg && data.EventImg[0]?
                    data.EventImg[0]
                    //   : ""
                  }
                  alt=""
                  className="img-fluid position-relative"
                />
                <div className="overlay ">
                  <p className="overlay-text">
                    <div className="eover-text">
                      <FontAwesomeIcon icon={faEye} />
                      <span>
                        {data.EventImg ? data.EventImg.length : 0} photos
                      </span>
                    </div>
                  </p>
                </div>
              </div>

              <p style={{ cursor: "pointer" }}>{data.EventName}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
