import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function EventDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    fetchEventData(id);
  }, [id]);

  const fetchEventData = async (eventId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_EVENTS}/${eventId}`
      );
      // const response = await axios.post(
      //   `http://admin.alcpune.com:8000/api/events/${eventId}`
      // );
      setEventData(response.data.event);
      console.log("event by id", response);
      console.log(`${process.env.REACT_APP_EVENTS}/${eventId}`);
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  return (
    <div>
      <div className="aboutbg">
        <div className="about-banner">
          <h2 className="title">Events</h2>
        </div>
      </div>
      <div className="container mt-5">
        <div className="d-flex flex-wrap">
          <div className="leftarr" onClick={() => navigate("/events")}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </div>
          {eventData && (
            <div className="edetail">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h2>{eventData.EventName}</h2>
              </div>
              <div className="d-flex flex-wrap mt-4">
                {eventData.EventImg.map((img, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={process.env.REACT_APP_API_URL + `${img}`}
                    alt={`Image ${imgIndex}`}
                    className="col-lg-6 col-md-6 col-sm-10 p-2 img-fluid"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
