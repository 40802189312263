import React from 'react'
import Event from './Events/Event';
import Notice from './Events/Notice';
import Announcement from './Events/Announcement';

export default function ENA() {
  return (
    <div className='d-flex container py-5 flex-wrap'>
      <div className='col-lg-4 col-12 mb-3 '>
        {/* <Event /> */}
        <Announcement  />
      </div>
      <div className='col-lg-4 col-12 mb-3 '>
        <Notice />
      </div>
      <div className='col-lg-4 col-12 mb-3 '>
        {/* <Announcement  /> */}
        <Event />
      </div>
    </div>
  )
}
