import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import axios from "axios";

export default function Announcement() {
  const [AnnounceData, setNoticeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getAnnounc = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_ANNOUNCEMENT);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/announcement"
        // );
        setNoticeData(response.data.announcements);
        console.log(response);
      } catch (error) {
        console.error("Error fetching announcement:", error);
        setError("Failed to fetch announcement");
      } finally {
        setLoading(false);
      }
    };

    getAnnounc();
  }, []);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      {AnnounceData.map((data, i) => (
        <div className="d-flex align-items-center flex-wrap " key={i}>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <p className="ntdate">{data.date}</p>
            <a
              href={data.link}
              onClick={() =>
                handleDownload(
                  process.env.REACT_APP_API_URL + data.announcementMedia
                )
              }
              className="linerm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ntname progtext m-0" style={{ cursor: "pointer" }}>
                {data.title}
                {i < 2 && (
                <span className="mx-1">
                  <Badge bg="danger">New</Badge>
                </span>
              )}
              </p>
            </a>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <p
              className="event-btn"
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleDownload(
                  process.env.REACT_APP_API_URL + data.announcementMedia
                )
              }
            >
              <span className="view-btn">View</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
