import React, { useEffect, useState } from "react";
import star from "../../assests/images/alumni/Group 273.png";
import axios from "axios";

export default function Job() {
  const [jobData, setjodData] = useState([]);

  useEffect(() => {
    const getjobData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_JOB);
        setjodData(response.data.jobs);
        console.log(response.data.jobs);
      } catch (error) {
        console.log("Failed to fetch notices", error);
        // setError("Failed to fetch notices");
      }
    };
    getjobData();
  }, []);

  const handleView = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      <div>
        <div className="headerdiv1 ">
          <img src={star} alt="" className="img-fluid" />
          <h2>JOBS</h2>
        </div>

        <div className="event ">
          {/* {jobData && jobData.length > 0 ? (
            jobData.map((job, index) => (
              <div
                className="px-3 pt-2 d-flex justify-content-between align-items-center"
                key={index}
              >
                <p className="job col-10">{job.Jobtitle}</p>
               
                {job.JobMedia && (
                  <div className="col-lg-2 col-md-3 col-sm-12">
                    <p
                      className="event-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleView(process.env.REACT_APP_API_URL + job.JobMedia)
                      }
                    >
                      <span className="view-btn">View</span>
                    </p>
                  </div>
                )}
              </div>
            ))
          ) : ( */}
            <p className="progtext px-3">
              We regret to inform you that the job is currently unavailable. We
              will keep you informed of any updates.
            </p>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}
