import React from "react";
import prosimg from "../../assests/images/others/prospectusimg.png";
import prosimg1 from "../../assests/images/others/Prospectusimg2025.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import pdf from "../../assests/images/pdf/ALC_Pune_Prospectus-2023 (1).pdf";
import pdf2 from "../../assests/images/pdf/ALC Pune Prospectus-AY 2025-26.pdf";

export default function Prospectous() {
  const prosData = [
    // {
    //   id: 1,
    //   img: prosimg,
    //   link: pdf,
    // },
    {
      id: 1,
      img: prosimg1,
      link: pdf2,
    },
  ];
  return (
    <div className="container" id="prospectous">
      <div className=" mt-5  d-flex justify-content-center ">
        {prosData.map((pros, i) => (
          <>
            <div className="formdiv prosimg m-2" key={i}>
              <a href={pros.link} download={`prospectus.pdf`}>
                <img src={pros.img} alt="" className="img-fluid p-2" />
              </a>
            </div>
          </>
        ))}
      </div>
      <div className="d-flex align-items-center mt-5">
        <div className="archive col-lg-2 col-sm-3">
          ARCHIVE <FontAwesomeIcon icon={faAngleRight} />
        </div>
        {/* <p className="progtext px-3 arc-sidetext col-lg-9 col-sm-9 p-3 mx-2 m-0">
          ALC PUNE PROSPECTUS 2024
        </p> */}
      </div>
    </div>
  );
}
