import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import img1 from "../../assests/images/testimonials/5.-NIDHI-MARTHAND.jpg";
import img2 from "../../assests/images/testimonials/4.-ARYAN-GAUR.jpg";
import img3 from "../../assests/images/testimonials/6.Shivani-Faujdar.jpeg";
import img4 from "../../assests/images/testimonials/3.SAYALI-BHOR.jpg";
import img5 from "../../assests/images/testimonials/1.Rakshita-Singh-Photo (1).jpg";
import img6 from "../../assests/images/testimonials/2.AMISHA-UPADHYAY (1).jpg";
import img7 from "../../assests/images/testimonials/Abhishek-MR-100x100.jpg";
import img8 from "../../assests/images/testimonials/Deah-Kapoor-100x100.jpg";
import img9 from "../../assests/images/testimonials/Vasupriya-Awasthi-100x100.jpg";
import img10 from "../../assests/images/testimonials/Diana-Carolyn-Peters-100x100.jpg";
import img11 from "../../assests/images/testimonials/Parthik-Choudhury-100x100.jpg";
import img12 from "../../assests/images/testimonials/Prerna-Manocha.jpg";

export default function Testimonials() {
  const testimonialData = [
    {
      img: img1,
      name: "Nidhi Martand",
      content:
        "Army Law College, Pune is the culmination of all the things that make life beautiful. Here, we have mother nature in whose arms we rest and rejuvenate ourselves. We have learned gurus who have such vast knowledge and wisdom that it lights a brilliant path for all the students. The College has a platform which provides us with various opportunities to prove our mettle and here we all love and support each other like a family.",
    },
    {
      img: img2,
      name: "Aryan Gaur",
      content:
        "Life is a privilege, but to live life to the fullest is a choice, and that choice itself gets fulfilled being in ALC with its accomplished faculty and effective staff who cater to all the academic needs, its healthy and competent environment which keeps the students engaged in activities and its awe inspiring location in the laps of nature which would help take a retreat from worldly stress. ",
    },
    {
      img: img3,
      name: "Shivani Faujdar",
      content:
        " I am reminded every day how fortunate I am to be a student at Army Law College. I truly believe that there is a place for everyone here. We have so much to learn with experienced and talented staff members and a management team on a green campus under beautiful infrastructure. Proud to be a part of ALC. ",
    },
    // {
    //     img:img4,
    //     name: "Sayali Bhor",
    //     content:" "
    // },
    {
      img: img5,
      name: "Rakshita Singh",
      content:
        "Be it Sports, extra-curricular, or academics, ALC gives you an efficient environment for everything. Our faculty and authorities have been our biggest supporters. Being a part of this institute since its inception in 2018 has helped me nurture my hidden talents to fruition. ",
    },
    {
      img: img6,
      name: "Amisha Upadhyay",
      content:
        "ALC provides everything a student needs for a prosperous future, from highly qualified faculty to administration that is always available with a helping hand. The positive competition radiates through the walls of ALC as a result of passion and dedication of the students who are blind to anything but knowledge of Law. It is an honour to walk through the hallways of ALC and enjoy the view of a sky full of bright stars, motivating us to reach unconquered heights, and mountains, empowering us with endless enthusiasm. ",
    },
    {
      img: img7,
      name: "Abhishek MR",
      content: `Life in ALC is full of experiences. From joining the college as a freshman, being independent and meeting new people from all corners of our nation having only one thing in common which is belonging to the Army background Exploring the 'College Phase' of life couldn't have been done better anywhere else in the Army Law College`,
    },
    {
      img: img8,
      name: "Deah Kapoor",
      content:
        "There are various committees ranging from the sports, dramatics to the various law societies that give opportunities to students to pursue their interests with their education.",
    },
    {
      img: img9,
      name: "Vasupriya Awasthi",
      content:
        "Peaceful environment, proficient faculty and extremely efficient staff, is a home away from home. Students are kept occupied and productive. The pleasant ambience of the college provides a calming escape from stress and tensity and the sports facilities provide healthy respite from the studies.",
    },
    {
      img: img10,
      name: "Diana Carolyn Peters",
      content: `The college also gives a lot of exposure to its students through moot courts, MUN's and various informative guest lectures on topics like Marketing Economics, Business management, Psychological issues faced by children of our age etc.`,
    },
    {
      img: img11,
      name: "Parthik Choudhury",
      content:
        "Vivacious Faculty, transcendent environment and delightful food, all in the lap of mother nature, what more do you need.",
    },
    {
      img: img12,
      name: "Prerna Manocha",
      content:
        "ALC is not just a college, it has been a home away from home, with the best of the facilities to make us feel at home.",
    },
  ];
  return (
    <div className="testimonials ">
      <div className="container py-5" style={{ cursor: "pointer" }}>
        <h2>Students Testimonials</h2>
        <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={100}
          slidesPerView={2}
          autoplay={{delay:6000}}
          pagination={{ clickable: true }}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 20 },
            425: { slidesPerView: 1, spaceBetween: 20 },
            999: { slidesPerView: 2, spaceBetween: 20 },
          }}
        >
          {testimonialData.map((data, index) => (
            <SwiperSlide key={index}>
              <div className="mt-5 d-flex justify-content-center flex-column align-items-center ">
                <img alt="" src={data.img} className="img-fluid" />
                <div className="testi-detail col-lg-10 col-sm-12">
                  <p className="testi-name ">{data.name}</p>
                  <p className="testi-content">{data.content}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
