import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import pdf1 from "../../assests/images/pdf/2nd Moot Court Competition-Quest Indicia- 2024.pdf";
import { Badge } from "react-bootstrap";
import pdf2 from "../../assests/images/others/Notice.pdf";
import pdf3 from "../../assests/images/others/Notice2.pdf";
import axios from "axios";

export default function Notices() {
  const [NoticeData, setNoticeData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  useEffect(() => {
    const getNotices = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_NOTICES);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/notice"
        // );
        setNoticeData(response.data.notices);
        console.log(response.data.notices);
      } catch (error) {
        console.error("Error fetching notices:", error);
        // setError("Failed to fetch notices");
      } finally {
        setLoading(false);
      }
    };

    getNotices();
  }, []);
  if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      {NoticeData.map((data, i) => (
        <div className="row  d-flex align-items-center flex-wrap " key={i}>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <p className="ntname progtext m-0" style={{ cursor: "pointer" }}>
              {data.title}{" "}
              {i < 2 && (
                <span className="mx-1">
                  <Badge bg="danger">New</Badge>
                </span>
              )}
            </p>

            <a href={data.link} className="linerm" target="_blank">
              <p className="ntdate">
                {data.date} <span className="mx-1"></span>
              </p>
            </a>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <p
              className="event-btn"
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleDownload(process.env.REACT_APP_API_URL + data.noticeMedia)
              }
            >
              <span className="view-btn">View</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
