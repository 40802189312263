import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faBars } from "@fortawesome/free-solid-svg-icons";
import mail from "../assests/images/alumni/email.png";
import { committeeData } from "../CommiteeData";
import { useParams } from "react-router-dom";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Cultural() {
  const { key } = useParams();
  const data = committeeData[key];

  const [activeComponent, setActiveComponent] = useState(["about", "job"]);

  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [jobsData, setJobsData] = useState([]);

  useEffect(() => {
    // Fetch job data when component mounts
    if (data.job && data.job.apiEndpoint) {
      axios
        .get(data.job.apiEndpoint)
        .then((response) => {
          setJobsData(response.data.jobs);
        })
        .catch((error) => {
          console.error("Error fetching job data:", error);
        });
    }
  }, [data.job]);

  const toggleMenu = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  // const handleClick = (componentName) => {
  //   // setActiveComponent(componentName);

  //   if (activeComponent.includes(componentName)) {
  //     // If active, remove it from active components
  //     setActiveComponent(
  //       activeComponent.filter((comp) => comp !== componentName)
  //     );
  //   } else {
  //     // If not active, add it to active components
  //     setActiveComponent([componentName]);
  //   }

  //   setIsMobileOpen(false);
  // };

  const handleClick = (componentName) => {
    setActiveComponent(componentName);
    setIsMobileOpen(false);
  };

  return (
    <div>
      {/* <div className="aboutbg">
        <div className="about-banner">
          <div className="flex-column w-100 container">
            <Link to="/collegeC">
              {" "}
              <div className=" p-2 leftarrow">
                <FontAwesomeIcon icon={faLeftLong} className="lefticon" />
              </div>
            </Link>
            <h2 className="proghead text-center">{committeeData[key].title}</h2>
          </div>
        </div>
      </div> */}

      <div style={{ position: "relative" }} className="background_insideBanner">
        {data.imageUrl && (
          <img
            src={data.imageUrl}
            alt="Background"
            className="background-image"
            // style={{ width: "100%", height: "80vh" }}
          />
        )}

        <div
          className="about-banner"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        >
          <div className="flex-column w-100 container">
            <Link to="/collegeC">
              <div className=" p-2 leftarrow">
                <FontAwesomeIcon icon={faLeftLong} className="lefticon" />
              </div>
            </Link>
            <h2 className="proghead text-center">{committeeData[key].title}</h2>
          </div>
        </div>
      </div>

      <div className="d-flex flex-wrap mt-5 container ">
        <FontAwesomeIcon
          icon={faBars}
          className="eventbar"
          onClick={toggleMenu}
        />
        {isMobileOpen && (
          <ul className="position-absolute mobiledropdown ">
            <div className="mobiledropdownMenu">
              {Object.keys(data).map((key, index) => (
                <li
                  key={key}
                  className={
                    activeComponent.includes(key) && index === 0
                      ? "Cdropdown dropheader"
                      : activeComponent.includes(key)
                      ? "Cdropdown "
                      : ""
                  }
                  style={{ display: data[key].title ? "" : "none" }}
                  onClick={() => handleClick(key)}
                >
                  {data[key].title}
                  {activeComponent.includes(key) && (
                    <FontAwesomeIcon icon={faAngleRight} className="icon" />
                  )}
                </li>
              ))}
            </div>
          </ul>
        )}

        <div className="col-lg-3 col-sm-12 col-md-4 px-3 mb-5">
          <div className="dropdownMenu">
            {Object.keys(data).map((key, index) => (
              <li
                key={key}
                className={
                  activeComponent.includes(key) && index === 0
                    ? "Cdropdown "
                    : activeComponent.includes(key)
                    ? "Cdropdown"
                    : ""
                }
                style={{ display: data[key].title ? "" : "none" }}
                onClick={() => handleClick(key)}
              >
                {data[key].title}
                {activeComponent.includes(key) && (
                  <FontAwesomeIcon icon={faAngleRight} className="icon" />
                )}
              </li>
            ))}
          </div>
        </div>
        <div className="col-lg-9 col-sm-12 col-md-12 px-2 ">
          {Object.keys(data).map(
            (key) =>
              activeComponent.includes(key) && (
                <div key={key}>
                  {key === "about" && (
                    <div>
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <p className="progtext">{data[key].content}</p>

                      {/* <div>
                        { 
                        data[key].coordinators.map((job, i) =>
                        <div className="m-2 col-lg-4 col-md-4 col-sm-8" key={i}>
                            <div className='teamcard' >
                                <img src={job.img} className="card-img-top img-fluid" alt="Card Image" />
                            </div>
                        </div>
                          ) 
                        }
                    </div> */}
                    </div>
                  )}
                  {key === "object" && (
                    <div className=" objectlist">
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <p className="progtext">{data[key].content}</p>
                      <div>
                        {data[key].data.map((list) => (
                          <ul key={list.id}>
                            <li className="progtext">
                              {list.id}. {list.list}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  )}
                  {key === "composition" && (
                    <div>
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <p className="progtext objectlist">{data[key].content}</p>
                      <div className="objectlist">
                        {data[key].data.map((list) => (
                          <ul key={list.id}>
                            {list.designation && (
                              <li className="progtext">{`${list.list} - ${list.designation}`}</li>
                            )}
                            {!list.designation && (
                              <li className="progtext">{list.list}</li>
                            )}
                          </ul>
                        ))}
                      </div>
                    </div>
                  )}

                  {key === "eligibility" && (
                    <div>
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <p className="progtext objectlist">{data[key].content}</p>
                    </div>
                  )}
                  {key === "functioning" && (
                    <div>
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <p className="progtext objectlist">{data[key].content}</p>
                    </div>
                  )}
                  {key === "resources" && (
                    <div>
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <p className="progtext objectlist">{data[key].content}</p>
                    </div>
                  )}
                  {key === "duties" && (
                    <div className=" objectlist">
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <div>
                        {data[key].data.map((list) => (
                          <ul key={list.id}>
                            <li className="progtext">
                              {list.id}. {list.list}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  )}
                  {key === "activity" && (
                    <div className="objectlist">
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <div>
                        {data[key].data.map((list) => (
                          <ul key={list.id}>
                            <li className="progtext">
                              {list.id}. {list.list}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  )}
                  {key === "prerequisite" && (
                    <div className="objectlist">
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <div>
                        {data[key].data.map((list) => (
                          <ul key={list.id}>
                            <li className="progtext">
                              {list.id}. {list.list}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  )}
                  {key === "contact" && (
                    <div>
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <div className="d-flex flex-wrap">
                        {data[key].coordinators.map((coimg, i) => (
                          <div
                            className="m-2 col-lg-4 col-md-4 col-sm-8"
                            key={i}
                          >
                            <div className="teamcard">
                              <img
                                src={coimg.img}
                                className="card-img-top img-fluid"
                                alt="Card Image"
                              />
                            </div>
                            <div className="card-body px-1 py-2 ">
                              <h5 className="teamname">{coimg.name}</h5>
                              <p className="destiny ">{coimg.destiny}</p>
                            </div>
                            <div className="col-12 py-2 text-center">
                              <img src={mail} alt=" " className="img-fluid " />
                              <span className=" px-1">{coimg.email}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {key === "rules" && (
                    <div className="objectlist">
                      <h2 className="progsubhead">{data[key].title}</h2>
                      <div>
                        {data[key].data.map((list) => (
                          <ul key={list.id}>
                            <li className="progtext">
                              {list.id}. {list.list}
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* 
                  {key === "job" && (
                    <div>
                      <h2 className="progsubhead">Advertisement for Post of Registrar & HOA ALC Pune -12 Jan 2025</h2>
                      <div className="d-flex flex-wrap">
                        {jobsData.map((job, i) => (
                          <div
                            className="m-2 col-lg-4 col-md-4 col-sm-8 mx-3 mt-5 mb-5"
                            key={i}
                          >
                            <div className="teamcard">
                              <img
                                src={
                                  process.env.REACT_APP_API_URL + job.JobMedia
                                }
                                className="card-img-top img-fluid"
                                alt="Card Image"
                              />
                              <a
                                href={
                                  process.env.REACT_APP_API_URL + job.JobMedia
                                }
                                download
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}

                  {key === "job" && (
                    <div>
                      <h2 className="progsubhead">
                        Advertisement for Post of Registrar & HOA ALC Pune -12
                        Jan 2025
                      </h2>
                      <div className="d-flex flex-wrap">
                        {jobsData.map((job, i) => {
                          const isPdf = job.JobMedia.toLowerCase().endsWith(
                            ".pdf"
                          );
                          return (
                            <div
                              className="m-2 col-lg-4 col-md-4 col-sm-8 mx-3 mt-5 mb-5"
                              key={i}
                            >
                              <div className="teamcard">
                                <h3>{job.title}</h3>
                                {!isPdf ? (
                                  <img
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      job.JobMedia
                                    }
                                    className="card-img-top img-fluid"
                                    alt="Card Image"
                                  />
                                ) : (
                                  <div className="teamcard">
                                    <iframe
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        job.JobMedia
                                      }
                                     className="card-img-top img-fluid"
                                    ></iframe>
                                  </div>
                                )}
                                <a
                                  href={
                                    process.env.REACT_APP_API_URL + job.JobMedia
                                  }
                                  download
                                >
                                  Download
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}
