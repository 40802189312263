import React, { useEffect, useRef, useState } from "react";
import pdfimg from "../../../assests/images/others/Group 1778.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";

import axios from "axios";

export default function Notice() {
  const [noticeData, setNoticeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);
  const scrollInterval = useRef(null);

  useEffect(() => {
    const getNotices = async () => {
      try {
        
        const response = await axios.get(process.env.REACT_APP_NOTICES);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/notice"
        // );
        setNoticeData(response.data.notices);

      } catch (error) {
        console.error("Error fetching notices:", error);
      } finally {
        setLoading(false);
      }
    };

    getNotices();
  }, []);

  useEffect(() => {
    if (noticeData.length === 0) return;

    const startScrolling = () => {
      scrollInterval.current = setInterval(() => {
        if (scrollRef.current) {
          if (
            scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
            scrollRef.current.scrollHeight
          ) {
            // Reset to top when reaching bottom
            scrollRef.current.scrollTo({ top: 0, behavior: "instant" });
          } else {
            scrollRef.current.scrollBy({
              top: 0.8, // Adjust speed
              behavior: "smooth",
            });
          }
        }
      }, 80); // Adjust interval for smoother scrolling
    };

    startScrolling();

    return () => clearInterval(scrollInterval.current);
  }, [noticeData]);

  const handleMouseEnter = () => clearInterval(scrollInterval.current);
  const handleMouseLeave = () => {
    scrollInterval.current = setInterval(() => {
      if (scrollRef.current) {
        if (
          scrollRef.current.scrollTop + scrollRef.current.clientHeight >=
          scrollRef.current.scrollHeight
        ) {
          scrollRef.current.scrollTo({ top: 0, behavior: "instant" });
        } else {
          scrollRef.current.scrollBy({
            top: 0.8,
            behavior: "smooth",
          });
        }
      }
    }, 80);
  };

  if (loading) return <p>Loading...</p>;

  const handleDownload = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div>
      <div className="headerdiv2">
        <img src={pdfimg} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2>Notice</h2>
          <Link to="/notices" className="linerm">
            {" "}
            <p className="mb-0">
            View All{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event p-3"
         ref={scrollRef}
         style={{
          //  maxHeight: "200px", // Adjust height as needed
           overflowY: "hidden",
         }}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
      >
        {noticeData.length > 0 &&
          noticeData.map((notice, index) => (
            <p
              className="notice py-2"
              style={{ cursor: "pointer" }}
              key={index}
            >
              <a
                href={notice.link}
                onClick={() =>
                  handleDownload(
                    process.env.REACT_APP_API_URL + notice.noticeMedia
                  )
                }
                className="linerm"
                target="_blank"
                rel="noopener noreferrer"
              >
                {notice.title}{" "}
                {index < 2 && (
                <span className="mx-1">
                  <Badge bg="danger">New</Badge>
                </span>
              )}
              </a>
            </p>
          ))}
      </div>
    </div>
  );
}
