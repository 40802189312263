import React, { useState, useEffect } from "react";
import eventicon from "../../../assests/images/others/Group 1777.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Event() {
  const [eventData, setEventsData] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchSessionList();
  }, []);

  useEffect(() => {
    fetchEvents(selectedYear);
  }, [selectedYear]);

  const fetchSessionList = async () => {
    try {
      const sessionResponse = await axios.get(process.env.REACT_APP_ALL_EVENTS);
      // const sessionResponse = await axios.get(
      //   "http://admin.alcpune.com:8000/api/allevent"
      // );
      const sessions = sessionResponse.data.SessionList || [];
      setSessionList(sessions);
      if (sessions.length > 0) {
        // Automatically select the latest session
        setSelectedYear(sessions[0]);
      }
    } catch (error) {
      console.error("Error fetching session list:", error);
    }
  };
  const fetchEvents = async (year) => {
    try {
      const response = await axios.post(process.env.REACT_APP_EVENTS, {
        eventSession: year,
      });
      console.log("eventimg", response);
      setEventsData(response.data.events || []);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };
  const lastThreeEvents = eventData.slice(-3);

  const handleClick = (name, id) => {
    navigate(`/event/${name}/${id}`);
  };

  return (
    <div>
      <div className="headerdiv1">
        <img src={eventicon} alt="" className="img-fluid" />
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h2>Events</h2>
          <Link to="/events" className="linerm">
            {" "}
            <p className="mb-0">
            View All{" "}
              <span>
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </p>
          </Link>
        </div>
      </div>
      <div className="event" style={{ cursor: "pointer" }}>
        {lastThreeEvents.map((event, index) => (
          <div
            className="event-content d-flex px-2 py-3 justify-content-between"
            onClick={() => handleClick(event.EventName, event._id)}
            key={index}
          >
            <div className="col-5 p-2">
              <img
                src={process.env.REACT_APP_API_URL + event.EventImg[0]}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-7">
              <h2 className="event-title">{event.EventName}</h2>
              <p className="event-date">{event.EventDate}</p>
              <p className="event-detail">{event.EventDescription}</p>
              {/* <p className='event-btn'>Read more</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
