import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import leftdesign from "../../assests/images/others/right-corner.png";
import rightdesign from "../../assests/images/others/left-corner.png";

import axios from "axios";

export default function Visitors() {
  const [visitorsData, setvisitorsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getVisitor = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_VISITORS);
        // const response = await axios.get(
        //   "http://admin.alcpune.com:8000/api/visitors"
        // );
        setvisitorsData(response.data.visitors);
        console.log(response);
      } catch (error) {
        console.error("Error fetching notices:", error);
      } finally {
        setLoading(false);
      }
    };
    getVisitor();
  }, []);
  if (loading) return <p>Loading...</p>;

  return (
    <div className="visitors">
      <div className="container py-5">
        <h2>What Our Visitors Says</h2>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={100}
          slidesPerView={1}
          autoplay={{ delay: 7000 }}
          speed={1900} // Slower slide transition speed (1.5 seconds)
          pagination={{ clickable: true }}
          // navigation
        >
          {visitorsData.map((data, index) => (
            <SwiperSlide key={index}>
              <div
                className="mt-5 d-flex justify-content-center align-items-center sub-visitor"
                style={{ cursor: "pointer" }}
              >
                <div className="col-lg-5  col-md-6 col-sm-5 text-center d-flex flex-column justify-content-center align-items-center vimg ">
                  <img
                    alt=""
                    src={process.env.REACT_APP_API_URL + data.visitorImg}
                    className="img-fluid visitorimg col-md-6"
                  />
                  <p className="vname mt-3">{data.visitorName}</p>
                  <p className="vposition">{data.visitorDesignation}</p>
                </div>
                <div className="d-flex align-items-center justify-content-center position-relative ">
                  <div className="note">
                    <div className="noteimg">
                      <img
                        src={process.env.REACT_APP_API_URL + data.visitorReview}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="left">
                      <img src={leftdesign} alt="" className="img-fluid" />
                    </div>
                    <div className="right">
                      <img src={rightdesign} alt="" className="img-fluid " />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
