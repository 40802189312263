import React from "react";
import Facilities from "../components/studentlife/Facilities";
import MServices from "../components/studentlife/MServices";
import Counselling from "../components/studentlife/Counselling";
import CodeofConduct from "../components/studentlife/CodeofConduct";
import Ragging from "../components/studentlife/commitee/Ragging";
import Grevieance from "../components/studentlife/Grevieance";
import Documents from "../components/studentlife/Documents";
import Sports from "../components/studentlife/Sports";
import GrevienceForm from "../components/studentlife/GrevienceForm";
import Commitees from "../components/studentlife/Commitees";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUser } from "@fortawesome/free-regular-svg-icons";

export default function StudentLife() {
  return (
    <div>
      <div className="aboutbg">
        <div className="about-banner">
          <h2 className="title">Student Life</h2>
        </div>
      </div>
      <div className="erp2">
        <a
          href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=937475"
          target="_blank"
        >
          <button className=" d-flex align-items-center ">
            <FontAwesomeIcon icon={faCircleUser} className="me-2" />
            <span>
              <small>Pay Fees/ Other Payments</small>
            </span>
          </button>
        </a>
      </div>
      <Facilities />
      <Sports />
      <MServices />
      <Counselling />
      <CodeofConduct />
      {/* <Ragging /> */}
      {/* <Grevieance /> */}
      <Commitees />
      <GrevienceForm />
      {/* <Documents /> */}
    </div>
  );
}
